import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

// Atoms
import { TextField } from '@mui/material';
import Button from '../atoms/button';

const ContactForm = () => {
  const [state, setState] = useState({
    submitting: false,
    step: 1,
    finalStep: false,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This is a required field'),
    emailAddress: Yup.string()
      .required('This is a required field')
      .email('Email must be formatted correctly'),
    message: Yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setState({ ...state, submitting: true });

    // Generating email body
    let ticketInfoString = '';
    ticketInfoString += '<h2>Careers Enquiry Form:</h2>';
    ticketInfoString += `<p><strong>Name:</strong> ${data.name}</p>`;
    ticketInfoString += `<p><strong>Email address:</strong> ${data.emailAddress}</p>`;
    ticketInfoString += `<p><strong>Message:</strong> ${data.message}</p>`;

    axios
      .post(
        'https://asfg35edw2.execute-api.ap-southeast-2.amazonaws.com/prod/careers',
        {
          payload: ticketInfoString,
        },
        {
          auth: {
            username: 'JupbZQRw3D',
            password: 'Nwt7eUju7w',
          },
        }
      )
      .then(() => {
        setState({ ...state, submitting: false, step: 2 });
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, submitting: false });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Transition
        appear
        show={state.step === 1}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="div"
        afterLeave={() => {
          setState({ ...state, finalStep: true });
        }}
      >
        <p className="u-body-md mb-4 lg:mb-6">
          Let us know your queries and our team will be in touch with you as
          soon as possible.
        </p>
        <div className="mb-5">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                error={!!errors?.name}
                helperText={errors?.name?.message}
                label="Name*"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="emailAddress"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                error={!!errors?.emailAddress}
                helperText={errors?.emailAddress?.message}
                label="Email Address*"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="message"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                rows={4}
                onChange={onChange}
                value={value}
                error={!!errors?.message}
                helperText={errors?.message?.message}
                label="How can we help you today?"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <Button
          as="button"
          type="submit"
          variant="primary"
          label="Submit"
          disabled={!isValid || state.submitting}
          aria-label="Submit the book a demo form"
        />
      </Transition>
      <Transition
        show={state.finalStep}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="div"
      >
        <p className="u-body-md mb-4 lg:mb-6">Success</p>
        <p className="u-body-xs">
          Thanks! If you&apos;ve provided all the information required,
          you&apos;ll hear from us in up to two business days via email.
        </p>
      </Transition>
    </form>
  );
};

export default ContactForm;
